<template>
  <vue-autosuggest
    v-model="terms"
    class="consumer_search_reference_no_input"
    :suggestions="suggestions"
    :input-props="inputProps"
    :section-configs="sectionConfigs"
    :render-suggestion="renderSuggestion"
    :get-suggestion-value="getSuggestionValue"
    @input="getMatches"
  />
</template>

<script>
import {
  BCard, BCardBody, BCardText, BAvatar,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import { ref, watch, computed } from '@vue/composition-api'
import _ from 'lodash'
import store from '@/store'

export default {
  components: {
    VueAutosuggest,
    BCard,
    BCardText,
    BAvatar,
    BCardBody,
  },
  methods: {
    renderSuggestion(suggestion) {
      return (
        <div class="flex">
          <span class="w-full font-bold text-center">
            {suggestion.item.reference_no}
          </span>
        </div>
      )
    },
    getSuggestionValue() {
      return this.terms
    },
  },
  setup(props, { root: $this, emit }) {
    const terms = ref(null)
    const selected = ref(null)
    const suggestions = ref([])
    const inputProps = ref({
      id: 'consumer_search_reference_no_input',
      placeholder: 'Reference No',
      class: 'form-control',
      name: 'consumer_search_reference_no',
    })
    const sectionConfigs = ref({
      consumers: {
        limit: 10,
        label: 'Results',
        onSelected: value => {
          selected.value = value.item
        },
      },
    })

    const getMatches = _.debounce(query => {
      if (!query) {
        suggestions.value = []
        return
      }
      store
        .dispatch('app-consumer-search/fetchSearchReferenceNo', {
          q: query,
        })
        .then(({ data }) => {
          suggestions.value = [
            {
              name: 'consumers',
              data: data.results,
            },
          ]
        })
        .catch(() => {})
    }, 300)

    watch(selected, () => {
      $this.$root.$emit('app-consumer-search-selected', selected.value)
    })

    return {
      terms,
      selected,
      suggestions,
      inputProps,
      sectionConfigs,

      getMatches,
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
.consumer_search_reference_no_input {
  .autosuggest__results {
    padding: 0.2rem;

    li {
      padding: 0.2rem;
      margin: 0.4rem;
      cursor: pointer;
      border-bottom: 1px solid $border-color;
    }
  }
  .autosuggest__results-before {
    color: $text-muted;
    margin: 0.2rem;
    text-align: center;
  }

  input::placeholder {
    // color:$primary;
    // opacity: 1;
  }
}
</style>
