<template>
  <b-card
    v-if="generalCard.state != 'HIDDEN'"
    no-body
    :class="{ 'area-busy': generalCard.state == 'WAITING' }"
  >
    <b-card-body>
      <div
        v-if="generalCard.data"
        class="grid grid-cols-3"
      >
        <div
          v-for="item in generalCard.data"
          :key="item.key"
          class="inline-flex gap-1"
        >
          <span class="font-bold whitespace-nowrap">{{ item.label }} : </span>
          <span>{{ item.value }}</span>
        </div>
      </div>

      <image-box
        v-if="generalCard.data"
        class="mx-auto mt-2 cursor-pointer"
        :src="generalCard.image"
        @click="setFullScreenImage(generalCard.image)"
      />

      <card-placeholder
        v-else
        :state="generalCard.state"
      />

      <image-full-screen
        :src="fullScreenImageUrl"
        @hidden="fullScreenImageUrl = null"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import ImageFullScreen from '@leecom/image-full-screen/ImageFullScreen.vue'
import {
  BTabs, BTab, BCard, BCardBody,
} from 'bootstrap-vue'
import ImageBox from '@leecom/image-box/ImageBox.vue'
import useInfo from '@/views/reports/single-consumer-consumption/consumer/useInfo'
import CardPlaceholder from '@leecom/card-placeholder/CardPlaceholder.vue'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BCardBody,
    BTabs,
    BTab,
    ImageBox,
    CardPlaceholder,
    ImageFullScreen,
  },
  setup() {
    const { generalCard } = useInfo()

    const fullScreenImageUrl = ref(null)

    const setFullScreenImage = url => {
      console.log(url);
      fullScreenImageUrl.value = url.replace('-400', '')
    }

    return {
      generalCard,

      setFullScreenImage,
      fullScreenImageUrl,
    }
  },
}
</script>

<style></style>
