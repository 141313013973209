<template>
  <b-modal
    :visible="visible"
    centered
    hide-footer
    size="xl"
    @hidden="hidden"
  >
    <b-img :src="src" class="mx-auto" />
  </b-modal>
</template>

<script>
import { BOverlay, BButton, BImg } from 'bootstrap-vue'

export default {
  components: {
    BOverlay,
    BButton,
    BImg,
  },
  props: {
    src: {
      required: true,
      type: String | null,
    },
  },
  computed: {
    visible() {
      return this.src != null
    },
  },
  methods: {
    hidden() {
      this.$emit('hidden', null)
    },
  },
}
</script>

<style></style>
