<template>
  <div class="max-w-[400px] max-h-[400px] overflow-hidden" @click="emitClick">
    <img
      class="w-full h-full"
      :src="src"
    >
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String | Object,
      required: true,
    },
  },
  methods:{
    emitClick($event){
      this.$emit('click', $event)
    }
  }
}
</script>

<style></style>
