<template>
  <div class="inline-flex w-full gap-x-2">
    <consumer-search-reference-no class="w-1/5" />
    <consumer-search-suggests class="w-full" />
  </div>
</template>
<script>
import ConsumerSearchSuggests from './ConsumerSearchSuggests.vue'
import ConsumerSearchReferenceNo from './ConsumerSearchReferenceNo.vue'
import consumerSearchStoreModule from './consumerSearchStoreModule'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'

export default {
  components: {
    ConsumerSearchReferenceNo,
    ConsumerSearchSuggests,
  },
  setup() {
    const STORE_MODULE_NAME = 'app-consumer-search'
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, consumerSearchStoreModule)
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })
  },
}
</script>
