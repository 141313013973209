<template>
  <div>
    <b-row v-if="consumer">
      <b-col cols="12">
        <h5 class="text-center">
          {{ consumer.reference_no }} | {{ consumer.serial_no }} |
          {{ consumer.consumer_name }} |
          {{ consumer.well_no }}
        </h5>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="9"
        class="mb-md-0 mb-2"
      >
        <consumer-search />
      </b-col>

      <b-col
        cols="12"
        md="3"
        class="mb-md-0 mb-2"
      >
        <date-picker
          :mode="mode"
          @date-picked="datePicked"
        />
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col
        cols="12"
        md="9"
        class="mb-md-0 mb-2"
      />
      <b-col
        cols="12"
        md="3"
        class="mb-md-0 mb-2"
      >
        <div class="flex justify-center gap-1">
          <b-dropdown
            v-if="$can('download', 'reports')"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            text="Generate"
            variant="primary"
            :disabled="disableGenerate"
            split
            right
            @click="emitGenerate()"
          >
            <b-dropdown-item
              :disabled="disableGenerate"
              @click="emitPDF()"
            >
              PDF
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="disableGenerate"
              @click="emitExcel()"
            >
              Excel
            </b-dropdown-item>
          </b-dropdown>
          <b-button
            v-else
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            :disabled="disableGenerate"
            @click="emitGenerate()"
          >
            Generate
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DatePicker from '@leecom/date-picker/DatePicker.vue'
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import ConsumerSearch from '@/@leecom/consumer-search/ConsumerSearch.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    ConsumerSearch,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    DatePicker,
    DatePicker,
  },
  directives: {
    Ripple,
  },
  props: {
    card: {
      type: Object,
    },
  },
  data() {
    return {
      STORE_MODULE_NAME: 'app-reports-single-consumer-consumption',
      consumer: null,
      rangeDate: null,
      disableGenerate: true,
      disableExports: true,
    }
  },
  computed: {
    mode() {
      return this.$store.state[this.STORE_MODULE_NAME].mode
    },
  },
  watch: {
    mode() {
      this.rangeDate = null
      this.canGenerate()
      this.resetActionData()
    },
    consumer() {
      this.canGenerate()
      this.resetActionData()
    },
    card() {
      console.log(this.card)
    },
  },
  created() {
    this.$root.$on('app-consumer-search-selected', event => {
      this.consumer = event
    })

    this.$root.$on('open-single-consumer-consumption-report', event => {
      this.consumer = event
    })
  },
  destroyed() {
    this.$root.$off('app-consumer-search-selected')
    this.$root.$off('open-single-consumer-consumption-report')
  },
  methods: {
    datePicked(date) {
      this.rangeDate = date
      this.canGenerate()
      this.resetActionData()
    },
    canGenerate() {
      this.disableGenerate = this.consumer === null || this.rangeDate === null
      return this.disableGenerate
    },
    setActionData() {
      const payload = {
        consumer: this.consumer,
        rangeDate: this.rangeDate,
      }
      this.$store.dispatch(`${this.STORE_MODULE_NAME}/setActionData`, payload)
    },
    resetActionData() {
      this.$store.dispatch(`${this.STORE_MODULE_NAME}/resetData`)
    },

    emitGenerate() {
      this.setActionData()
      const payload = {
        filters: this.filters,
        rangeDate: this.rangeDate,
      }
      this.$emit('generate', payload)
    },
    emitPDF() {
      this.setActionData()
      const payload = {
        filters: this.filters,
        rangeDate: this.rangeDate,
      }
      this.$emit('open-pdf', payload)
    },
    emitExcel() {
      this.setActionData()
      const payload = {
        filters: this.filters,
        rangeDate: this.rangeDate,
      }
      this.$emit('download-excel', payload)
    },
  },
}
</script>
