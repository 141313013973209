import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import _ from 'lodash'
import moment from "moment-timezone";

moment.locale("en");
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useSingleConsumerTableList() {

    const toast = useToast()
    const refDataListTable = ref(null)
    const STORE_MODULE_NAME = 'app-reports-single-consumer-consumption'


    // *===============================================---*
    // *--------- Data List Table ---------------------*
    // *===============================================---*
    const tableColumns = [
        { field: 'index', sortable: false, filter: false, label: '' },
        { field: 'date', sortable: false, filter: false, label: 'Date of reading' },
        { field: 'time', sortable: false, filter: false, label: 'Time' },
        { field: 'flow', sortable: false, filter: false, label: 'Pump flow rate' },
        { field: 'reverse_flow', sortable: false, filter: false, label: 'Reverse flow' },
        { field: 'total_plus', sortable: false, filter: false, label: 'Cumulative total ' },
        { field: 'consumption', sortable: false, filter: false, label: 'Usage', },
    ]

    const perPage = ref(10)
    const totalRows = computed(() => {
        return tableCard.value.data == null ? 0 : tableCard.value.data.total;
    })
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]

    const sortBy = ref('date')
    const isSortDirDesc = ref(false)

    const dataMeta = computed(() => {
        const rows = refDataListTable.value ? refDataListTable.value._props.rows : []

        let localItemsCount = 0;
        // rows.forEach((row) => {
        //     // const size = row.children ? row.children.length : 0;

        //     localItemsCount += 1;
        // })
        localItemsCount = rows.length;
        // console.log(localItemsCount);

        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalRows.value,
        }
    })

    const actionData = computed(() => {
        return store.state[STORE_MODULE_NAME].actionData
    })

    const mode = computed(() => {
        return store.state[STORE_MODULE_NAME].mode
    })

    const tableCard = computed(() => {
        return store.state[STORE_MODULE_NAME].tableCard
    })

    const tableItems = computed(() => {
        return tableCard.value.data == null ? [] : tableCard.value.data.data;
    })

    watch([currentPage, perPage], () => {
        fetchTable()
    })

    const fetchTable = () => {
        store
            .dispatch(`${STORE_MODULE_NAME}/setTable`, {
                state: 'WAITING',
            })

        const consumer = actionData.value.consumer
        const { startDate, endDate } = actionData.value.rangeDate

        const payload = {
            serialNo: consumer.serial_no,
            queryParams: {
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
            },
            startTimestamp: moment
                .tz(startDate.format('YYYY-MM-DD 00:00:00'), 'UTC')
                .valueOf(),
            endTimestamp: moment
                .tz(endDate.format('YYYY-MM-DD 23:59:59'), 'UTC')
                .valueOf(),
        }
        return new Promise((resolve, reject) => {
            store
                .dispatch(`${STORE_MODULE_NAME}/fetchTable`, payload)
                .then((response) => {
                    store
                        .dispatch(`${STORE_MODULE_NAME}/setTable`, {
                            data: response,
                            state: 'OK',
                        })
                    resolve(response);
                })
                .catch((error) => {
                    store
                        .dispatch(`${STORE_MODULE_NAME}/setTable`, {
                            data: null,
                            state: 'ERROR',
                        })
                    reject(error);
                })
        });
    }

    const resolveUnitLabel = (key) => {
        return _.get(_.find(store.state.app.enums.DisplayUnitType, { key: key }), 'label', null);
    }

    const resolveModeText = () => {
        return _.get(_.find(store.state.app.enums.ReportModeType, { key: mode.value.toUpperCase() }), 'label', null);
    }

    const resolveTotalConsumption = () => {
        const rows = tableItems.value;
        return  rows && rows.length > 0 ? rows[0].total_consumption : null
    }

    return {
        tableCard,

        tableColumns,
        refDataListTable,
        tableItems,

        perPage,
        currentPage,
        totalRows,
        dataMeta,
        perPageOptions,

        sortBy,
        isSortDirDesc,

        resolveUnitLabel,
        resolveModeText,
        resolveTotalConsumption,
    }
}
