
import {
    ref, computed, watch, onMounted,
} from '@vue/composition-api'
import store from '@/store'
import { customerGetLabelForKey } from '@leecom/utils/support'
import _ from 'lodash'

export default function useInfo() {
    const STORE_MODULE_NAME = 'app-reports-single-consumer-consumption'

    const meterSizeOptions = computed(() => (store.state.app.enums ? store.state.app.enums.MeterSizeType : []))
    const meterModelOptions = computed(() => (store.state.app.enums ? store.state.app.enums.MeterModelType : []))
    const consumerTypeOptions = computed(() => (store.state.app.enums ? store.state.app.enums.ConsumerType : []))
    const aquifersTypeOptions = computed(() => (store.state.app.enums ? store.state.app.enums.AquifersType : []))
    const waterSourceTypeOptions = computed(() => (store.state.app.enums ? store.state.app.enums.WaterSourceType : []))
    const pumpTypeOptions = computed(() => (store.state.app.enums ? store.state.app.enums.PumpType : []))
    const governorateTypeOptions = computed(() => (store.state.app.enums ? store.state.app.enums.GovernorateType : []))
    const customerStatusType = computed(() => (store.state.app.enums ? store.state.app.enums.CustomerStatusType : []))

    const actionData = computed(
        () => store.state[STORE_MODULE_NAME].actionData,
    )

    const generalCard = computed(() => {
        const chartCard = _.cloneDeep(store.state[STORE_MODULE_NAME].chartCard)
        chartCard.data = chartCard.data ? items.value : null
        chartCard.image = chartCard.data ? image.value : null

        return chartCard;
    })

    const assetsPath = computed(() => store.state.appConfig.layout.assetsPath)
    const placeholderImage = computed(
        () => store.state.appConfig.layout.noImagePlaceholder,
    )

    const items = ref(null)
    const image = ref(null)

    const visibleKeys = [
        'well_no',
        'reference_no',
        'serial_no',
        'meter_model',
        'meter_size',
        'water_source',
        'aquifers',
        'pump_type',
        'consumer_name',
        'consumer_type',
        'cpr_no',
        'address',
        'po_box',
        'governorate',
        'mobile',
        'email',
        'office_phone',
        'plot_no',
        'gps',
        'water_quality',
        'status',
    ]

    watch(actionData, () => {
        if (!actionData.value) {
            return
        }
        prepareDate()
    })

    const prepareDate = () => {
        const rawData = actionData.value.consumer

        const visibleData = _.chain(rawData)
            .map((value, key) => {
                if (!visibleKeys.includes(key)) {
                    return null
                }
                return {
                    key,
                    label: customerGetLabelForKey(key),
                    value: resolveValueForEnums(value, key),
                }
            })
            .filter(item => item !== null)
            .value()

        visibleData.sort(
            (a, b) => visibleKeys.indexOf(a.key) - visibleKeys.indexOf(b.key),
        )
        items.value = visibleData
        image.value = rawData.image
            ? `${assetsPath.value}/images/customers/${rawData.image}`
            : placeholderImage.value
    }

    const resolveValueForEnums = (value, key) => {
        if (key == 'meter_size') {
            return _.get(_.find(meterSizeOptions.value, { value }), 'label', null)
        }

        if (key == 'meter_model') {
            return _.get(_.find(meterModelOptions.value, { value }), 'label', null)
        }

        if (key == 'consumer_type') {
            return _.get(
                _.find(consumerTypeOptions.value, { value }),
                'label',
                null,
            )
        }

        if (key == 'aquifers') {
            return _.get(
                _.find(aquifersTypeOptions.value, { value }),
                'label',
                null,
            )
        }

        if (key == 'water_source') {
            return _.get(
                _.find(waterSourceTypeOptions.value, { value }),
                'label',
                null,
            )
        }

        if (key == 'pump_type') {
            return _.get(_.find(pumpTypeOptions.value, { value }), 'label', null)
        }

        if (key == 'governorate') {
            return _.get(
                _.find(governorateTypeOptions.value, { value }),
                'label',
                null,
            )
        }

        if (key == 'status') {
            return _.get(
                _.find(customerStatusType.value, { value }),
                'label',
                null,
            )
        }

        return value
    }

    return {
        generalCard
    }
}