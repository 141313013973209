<template>
  <div>
    <b-card
      no-body
      :class="{ 'area-busy': tableCard.state == 'WAITING' }"
    >
      <b-card-body>
        <!-- table -->
        <vue-good-table
          v-if="tableCard.data"
          ref="refDataListTable"
          class="position-relative table-data for-report"
          style-class="vgt-table"
          mode="remote"
          :fixed-header="false"
          :columns="tableColumns"
          :rows="tableItems"
          :rtl="$store.state.appConfig.isRTL"
          :pagination-options="{
            enabled: true,
            perPage: perPage,
          }"
        >
          <template
            slot="table-column"
            slot-scope="props"
          >

            <!-- Column Label: Consumption -->
            <span
              v-if="props.column.field == 'consumption'"
              class="text-right w-full block"
            >
              {{ resolveModeText() + ' ' + props.column.label }}
              <span
                class="!lowercase"
              >({{ resolveUnitLabel('CUBIC_METER') }})</span>
            </span>

            <!-- Column Label: Total Plus -->
            <span
              v-else-if="props.column.field == 'total_plus'"
              class="text-right w-full block"
            >
              {{ props.column.label }}
              <span
                class="!lowercase"
              >({{ resolveUnitLabel('CUBIC_METER') }})</span>
            </span>

            <!-- Column Label: Flow Rate -->
            <span
              v-else-if="props.column.field == 'flow'"
              class="text-right w-full block"
            >
              {{ props.column.label }}
              <span
                class="!lowercase"
              >({{ resolveUnitLabel('CUBIC_METER_PER_HOUR') }})</span>
            </span>

            <!-- Column Label: Reverse Flow -->
            <span
              v-else-if="props.column.field == 'reverse_flow'"
              class="text-right w-full block"
            >
              {{ resolveModeText() + ' ' + props.column.label }}
              <span
                class="!lowercase"
              >({{ resolveUnitLabel('CUBIC_METER') }})</span>
            </span>

            <span v-else>
              {{ props.column.label }}
            </span>
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Index -->
            <span v-if="props.column.field === 'index'">
              {{ props.row.originalIndex + 1 }}
            </span>

            <!-- Column: Meter Size -->
            <span v-if="props.column.field === 'meter_size'">
              {{ resolveMeterSizeLabel(props.row.meter_size) }}
            </span>

            <!-- Column: Consumer Type -->
            <span v-else-if="props.column.field === 'consumer_type'">
              {{ resolveMeterSizeLabel(props.row.consumer_type) }}
            </span>

            <!-- Column: Consumption -->
            <span
              v-else-if="props.column.field === 'consumption'"
              class="text-right block w-full"
            >
              {{ props.row.consumption }}
            </span>

            <!-- Column: Flow Rate -->
            <span
              v-else-if="props.column.field === 'flow'"
              class="text-right block w-full"
            >
              {{ props.row.flow }}
            </span>

            <!-- Column: Reverse Flow -->
            <span
              v-else-if="props.column.field === 'reverse_flow'"
              class="text-right block w-full"
            >
              {{ props.row.reverse_flow }}
            </span>

            <!-- Column: Total Plus -->
            <span
              v-else-if="props.column.field === 'total_plus'"
              class="text-right block w-full"
            >
              {{ props.row.total_plus }}
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <template
            slot="table-header-row"
            slot-scope="props"
          >
            <!-- Column: Consumption -->
            <span
              v-if="props.column.field === 'consumption'"
              class="text-right block w-full"
            >
              {{ props.row.consumption }}
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="mx-2 mb-2">
              <b-row v-if="resolveTotalConsumption()">
                <b-col cols="12" sm="9"/>
                <b-col cols="12" sm="3">
                    <label class="font-bold text-[1rem] text-right mt-1 w-full">Total Usage: {{resolveTotalConsumption()}}  {{resolveUnitLabel('CUBIC_METER')}}</label>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  class="flex flex-col mb-1 mb-md-0"
                >
                  <span
                    class="mb-1"
                  >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                    {{ totalRows }} entries</span>
                  <div>
                    <label>Show</label>
                    <v-select
                      v-model="perPage"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="perPageOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block mx-50"
                    />
                    <label>entries</label>
                  </div>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </template>
        </vue-good-table>

        <card-placeholder
          v-else
          :state="tableCard.state"
        />
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSidebar,
  BTr,
  BTh,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useTable from './useTable'
import CardPlaceholder from '@leecom/card-placeholder/CardPlaceholder.vue'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BTr,
    BTh,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSidebar,

    VueGoodTable,

    vSelect,

    CardPlaceholder,
  },
  setup() {
    const {
      tableCard,

      tableColumns,
      refDataListTable,
      tableItems,

      perPage,
      currentPage,
      totalRows,
      dataMeta,
      perPageOptions,

      sortBy,
      isSortDirDesc,

      resolveUnitLabel,
      resolveModeText,
      resolveTotalConsumption,
    } = useTable()

    return {
      tableCard,

      tableColumns,
      refDataListTable,
      tableItems,

      perPage,
      currentPage,
      totalRows,
      dataMeta,
      perPageOptions,

      sortBy,
      isSortDirDesc,

      resolveUnitLabel,
      resolveModeText,
      resolveTotalConsumption,
    }
  },
}
</script>
