<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-tabs
          align="center"
          @activate-tab="tabChanged"
        >
          <b-tab
            title="Daily"
            active
          />
          <!-- <b-tab title="Weekly" /> -->
          <b-tab title="Monthly" />
          <b-tab title="Yearly" />
        </b-tabs>
        <single-consumer-consumption-actions
          @generate="generateReport"
          @open-pdf="openPDF"
          @download-excel="downloadExcel"
        />
      </b-card-body>
    </b-card>
    <div>
      <report-title :card="titleCard" />
      <single-consumer-consumption-chart />
      <single-consumer-consumption-table ref="refTableCard" />
      <single-consumer-consumption-info />
      <busy-modal :card="busyCard" />
    </div>
  </div>
</template>

<script>
import ReportTitle from '@leecom/report-title/ReportTitle.vue'
import { ref, onUnmounted, onBeforeUnmount, computed } from '@vue/composition-api'
import store from '@/store'
import {
  BTabs, BTab, BCard, BCardBody,
} from 'bootstrap-vue'
import singleConsumerConsumptionStoreModule from '@/views/reports/single-consumer-consumption/singleConsumerConsumptionStoreModule'
import useFetchData from '@/views/reports/single-consumer-consumption/useFetchData'
import BusyModal from '@/@leecom/busy-modal/BusyModal.vue'
import moment from 'moment-timezone'
import SingleConsumerConsumptionTable from './table/SingleConsumerConsumptionTable.vue'
import SingleConsumerConsumptionChart from './chart/SingleConsumerConsumptionChart.vue'
import SingleConsumerConsumptionActions from './SingleConsumerConsumptionActions.vue'
import SingleConsumerConsumptionInfo from './consumer/SingleConsumerConsumptionInfo.vue'

moment.locale('en')
moment.tz('UTC')

export default {
  components: {
    BCard,
    BCardBody,
    BTabs,
    BTab,

    SingleConsumerConsumptionActions,
    SingleConsumerConsumptionChart,
    SingleConsumerConsumptionTable,
    SingleConsumerConsumptionInfo,
    ReportTitle,
    BusyModal,
  },
  setup(props, { root: $this }) {
    const STORE_MODULE_NAME = 'app-reports-single-consumer-consumption'
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(
        STORE_MODULE_NAME,
        singleConsumerConsumptionStoreModule,
      )
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const tabChanged = event => {
      store.dispatch(`${STORE_MODULE_NAME}/resetData`)
      let mode = 'day'
      if (event == 0) {
        mode = 'day'
      } else if (event == 1) {
        mode = 'month'
      } else if (event == 2) {
        mode = 'year'
      }
      store.dispatch(`${STORE_MODULE_NAME}/setMode`, mode)
    }

    // reset state when loading page
    tabChanged(0)

    $this.$root.$on('open-single-consumer-consumption-report', event => {
      generateReportWithDebounce(event)
    })

    onBeforeUnmount(() => {
       $this.$root.$off('open-single-consumer-consumption-report');
    })

    const generateReportWithDebounce = _.debounce(event => {
      const endDate = moment().endOf('month').isAfter(moment())
        ? moment()
        : moment().endOf('month')

      const payload = {
        consumer: event,
        rangeDate: {
          startDate: moment().startOf('month'),
          endDate,
        },
      }
      store.dispatch(`${STORE_MODULE_NAME}/setActionData`, payload)

      generateReport()
    }, 300)

    const {
      generateReport,
      openPDF,
      downloadExcel,

      titleCard,
      busyCard,

      refTableCard,
    } = useFetchData()

    return {
      tabChanged,

      generateReport,
      openPDF,
      downloadExcel,

      titleCard,
      busyCard,

      refTableCard,
    }
  },
}
</script>
